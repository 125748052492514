import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography } from '@mui/material';
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import { AuthContext } from '../components/Auth/Auth';
import Sidenav from "../components/NavBars/Sidenav";
import Loading from "../components/commonComponents/Loading";
import ClubForm from './ClubForm';
import ClubList from './ClubList';
import { db } from "./firebase"; // Your Firestore initialization

const categories = ["Technology", "Finance", "Entreprenurship", "Medicine", "Creative Writing", "Environment"];

export default function Clubs() {
  const token = localStorage.getItem('token');
  console.log("currentUser token: ", token)

  const profile = JSON.parse(localStorage.getItem('profile'));

  const currentUser = profile.response.user;
  console.log("currentUser token: ", currentUser)

  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [open, setOpen] = useState(false);
  const [newClub, setNewClub] = useState({ name: '', url: '', category: '', admin: currentUser.id });
  const [userMemberships, setUserMemberships] = useState([]);



  useEffect(() => {
    const fetchClubs = async () => {
      setLoading(true);
      try {
        const clubSnapshot = await getDocs(collection(db, "clubs"));
        const clubList = clubSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClubs(clubList);
        setFilteredClubs(clubList);

        const membershipsSnapshot = await getDocs(query(collection(db, "memberships"), where("user", "==", currentUser.id)));
        setUserMemberships(membershipsSnapshot.docs.map(doc => doc.data()));
      } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchClubs();
  }, [currentUser.id]);

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setSelectedCategories(value);
    setFilteredClubs(clubs.filter(club => !value.length || value.includes(club.category)));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewClub(prevClub => ({ ...prevClub, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Usage inside `handleCreateClub`
  const handleCreateClub = async () => {
    if (newClub.name && newClub.url && newClub.category) {
      try {
        const docRef = await addDoc(collection(db, "clubs"), newClub);
        await setDoc(doc(db, "memberships", `${currentUser.id}_${docRef.id}`), {
          user: currentUser.id, clubId: docRef.id, role: "admin", dateJoined: new Date()
        });
        setClubs([...clubs, { ...newClub, id: docRef.id }]);
        setFilteredClubs([...clubs, { ...newClub, id: docRef.id }]);
        handleClose();  // Close modal after creating the club
        setNewClub({ name: '', url: '', category: '', admin: currentUser.id });  // Reset the form
      } catch (error) {
        console.error("Error adding club:", error);
      }
    }
  };

  const clubsIAmMemberOf = clubs.filter(club => userMemberships.some(membership => membership.clubId === club.id));
  const clubsNotAMemberOf = clubs.filter(club => !userMemberships.some(membership => membership.clubId === club.id));

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Sidenav />
      <Box sx={{ flexGrow: 1, padding: 2 }}>


        <Button variant="contained" sx={{ mt: 2 }} onClick={() => setOpen(true)}>Create Club</Button>

        <ClubForm open={open} onClose={() => setOpen(false)} newClub={newClub} handleInputChange={handleInputChange} handleCreateClub={handleCreateClub} />

        {loading ? <Loading /> : (
          <>
            <ClubList clubs={clubsIAmMemberOf} title="Clubs I'm a Member Of" />

            <Typography> Explore clubs</Typography>
            <ClubList clubs={clubsNotAMemberOf} title="" />
          </>
        )}
      </Box>
    </Box>
  );
}
