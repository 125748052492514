// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define color variables
export const colors = {
  primaryMain: '#47979D',
  primaryContrastText: '#FAFAFA',
  secondaryMain: '#54747A',
  errorMain: '#EF5350',
  textPrimary: '#222222',
  textSecondary: '#828282',
  backgroundDefault: '#E9E9E9',
  buttonHoverBackground: '#31696d',
  buttonTextColor: '#FAFAFA',
  violet:'#7E57C2',
  blue:'#29B6F6'
};

let theme = createTheme({
});

theme = createTheme(theme,{
  palette: {
    primary: {
      main: colors.primaryMain,
      contrastText: colors.primaryContrastText,
    },
    secondary: {
      main: colors.secondaryMain,
      contrastText: colors.primaryContrastText,
    },
    error: {
      main: colors.errorMain,
      contrastText: colors.primaryContrastText,
    },
    violet: theme.palette.augmentColor({
      color: {
        main: colors.violet,
        contrastText: colors.primaryContrastText
      },
      name: 'violet',
    }),
    blue: theme.palette.augmentColor({
      color: {
        main: colors.blue,
        contrastText: colors.primaryContrastText,
      },
      name: 'blue',
    }),
    background: {
      default: colors.backgroundDefault,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '0.9375rem', // corrected font size
      fontWeight: 500,
    },
    body1: {
      fontSize: '0.9375rem',
    },
    button: {
      textTransform: 'none', // Disable uppercase in buttons
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'contained', variant:'contained' },
              style: {
                borderRadius: '1.125rem',
                color: colors.buttonTextColor,
                textWrap: 'nowrap',
                ':hover': {
                  background: colors.buttonHoverBackground,
                  color: colors.buttonTextColor,
                },
              },
            },
            {
              props: { variant: 'text' },
              style: {
                borderRadius: '1.125rem',
                padding: '0.375rem 1rem',
                textWrap: 'nowrap',
                ':hover': {
                  background: colors.buttonHoverBackground,
                  color: colors.buttonTextColor,
                },
              },
            },
          ],
        },
      },
    },
  },
});

export default theme;
