import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import { getCurrentUser, userId, get, post } from "../components/Helper";
import { db } from "./firebase"; // Your Firestore initialization

function ClubTabs({ tabValue, handleTabChange, members, clubData, clubId }) {
    const [name, SetName] = useState([]);
    const [projects, setClubProjects] = useState([]);

    const profile = JSON.parse(localStorage.getItem('profile'));

    const currentUser = profile.response.user;



    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const clubSnapshot = await getDocs(collection(db, "club-projects"));
                const clubProjectList = clubSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(doc => doc.club === clubId); // Filter by clubId

                setClubProjects(clubProjectList);
            } catch (error) {
                console.error("Error fetching clubs or memberships:", error);
            } finally {
                console.log(true);
            }
        };

        fetchClubs();
    }, [currentUser.id, clubId]); // Make sure to include clubId in the dependency array


    const updates = [
        {
            timestamp: "2024-09-10 10:30 AM",
            person: "Roundpier",
            updateDescription: "Sample website for guidance: www.feedcapture.com"
        },
        {
            timestamp: "2024-09-10 10:30 AM",
            person: "PZ",
            updateDescription: "The link for making nice websites: https://www.w3schools.com/"
        },
        ,
        {
            timestamp: "2024-09-10 10:30 AM",
            person: "Roundpier",
            updateDescription: "Help for project Hackathon: https://www.youtube.com/watch?v=lwz5bMN8jX8&list=RDATl2X&index=4"
        }
    ];

    useEffect(() => {
        get("/user/view/98954?token=98954_2785cbfc7a5a89f7d74958b1befcb7ee")
            .then((data) => {
                SetName(data.response.user.name);
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="club tabs">
                    <Tab label="Members" />
                    <Tab label="Projects" />
                    <Tab label="Updates" />
                </Tabs>
            </Box>

            {/* Members Tab */}
            {tabValue === 0 && (
                <Box sx={{ padding: 2 }}>
                    {/* <Card sx={{ marginBottom: 2 }}>
                        
                        <CardContent>
                            <Typography variant="body1" paragraph>
                                <strong>Admin:</strong> {clubData.admin}
                            </Typography>
                        </CardContent>
                    </Card> */}

                    <Grid container spacing={2}>
                        {members.map((member, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body1">
                                            {member}
                                        </Typography>
                                        <Typography variant="body1">
                                            {name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {/* Projects Tab */}
            {tabValue === 1 && (
                <Box sx={{ padding: 2 }}>
                    {projects.length > 0 ? (
                        <Grid container spacing={2}>
                            {projects.map((project, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5" component="h3">
                                                {project.title}
                                            </Typography>
                                            <Typography variant="h6">
                                                <strong>Start:</strong> {project.startDate} <br />
                                                <strong>End:</strong> {project.endDate}
                                            </Typography>
                                            <Typography >
                                                {project.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography>No projects available.</Typography>
                    )}
                </Box>
            )}

            {/* Updates Tab */}
            {tabValue === 2 && (
                <Box sx={{ padding: 2 }}>
                    {updates.length > 0 ? (
                        <Grid container spacing={2}>
                            {updates.map((update, index) => (
                                <Grid item xs={12} key={index}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="body1">
                                                <strong>{update.person}</strong> ({update.timestamp}):
                                            </Typography>
                                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                {update.updateDescription}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography>No updates available.</Typography>
                    )}
                </Box>
            )}
        </>
    );
}


export default ClubTabs;
