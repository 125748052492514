import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, CssBaseline } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../../assets/svg/logo.svg';
import { Link as RouterLink } from 'react-router-dom';


export default function Header(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        component="header"
        sx={{
          position: 'sticky',  // Make it stick to the top
          top: 0,              // Stick at the top
          zIndex: 1100,        // Ensure it stays above other content
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          px: { xs: 2, s: 8, md: 10, lg: 25 },
          background: '#FAFAFA',
          py: 2,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional for visual separation
        }}
      >
        {/* Logo */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              width: { xs: '90px', sm: '120px', md: '169px' },
              height: 'auto',
            }}
          >
            <img src={logo} alt="Roundpier" style={{ paddingTop: '0.75rem' }} />
          </Box>
        </Link>

        {/* Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
          <Button
            component={RouterLink}
            to='/signin'
            startIcon={<LoginIcon />}
            sx={{ mr: { xs: .75, md: 2 }, width: '5.625rem' }}
          >
            Log in
          </Button>
          <Button 
          component={RouterLink}
          to='https://www.roundpier.com/#sign_up_modal'
          variant="contained"
          sx={{ width: '5.625rem' }}>
            Sign up
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
