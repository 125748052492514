import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useRoutes,
  Navigate,
  Redirect,
} from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/Auth/Auth";
import Signin from "./components/Auth/Signin";
import Signup from "./components/Auth/Signup";
import Clubs from "./pages/clubs";
import LandingPage from "./pages/LandingPage";
import Club from "./pages/club";
import { ThemeProvider } from '@mui/material/styles';
import Profile from "./pages/profile";
import SmoothScroll from "smooth-scroll";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/ErrorFallback";
import theme from './theme';
// import { getCurrentUser } from "./components/Helper";
import ProtectedRoutesComponent from "./components/commonComponents/ProtectedRoutesComponent";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <AuthProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/signin" element={<Signin />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/" element={<LandingPage />} />
              {/* <Route path="/competitions" element={<Competitions />} /> */}
              <Route path="*" element={<Navigate to="/" />} />
              <Route
                element={<ProtectedRoutesComponent />}
              >
                <Route exact path='/account' element={<Profile />}></Route>
                <Route exact path='/clubs' element={<Clubs />}></Route>
                {/* <Route exact path='/competitions' element={<Clubs />}></Route> */}
                <Route exact path="/club" element={<Club />}></Route>
              </Route>
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </AuthProvider>
  );
};

export default App;
