import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CssBaseline
} from "@mui/material";
import { Navigate } from 'react-router-dom';
import Header from "../components/LandingPage/Header";
import { Banner } from "../components/LandingPage/Banner";

import CompetitionsData from "../data/competitionsData.json";
import landingPageJson from "../data/competitionsPageData.json";
import JsonData from "../data/competitionsPageData.json";

import { getCurrentUser, getUserId } from '../components/Helper';
import Overview from "../components/LandingPage/Overview";
import Footer from "../components/LandingPage/Footer";
import CompetitionOverview from "../components/LandingPage/CompetitionOverview";
import WinnersCards from "../components/LandingPage/WinnersCards";
import Testimonials from "../components/LandingPage/Testimonials";



const LandingPage = () => {
    const [competitionsData, setCompetitionsData] = useState([]);
    const [landingPageData, setLandingPageData] = useState({});
    const currentUser = getCurrentUser();


    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    useEffect(() => {
        // Load data from the file or API
        setCompetitionsData(CompetitionsData);
    }, []);

    if (currentUser) {
        return <Navigate to="/clubs" />;
    }
    return (
        <div >
            <CssBaseline />
            <Header />

            <Banner data={landingPageData.Header} />
            <Box sx={{ mt: 6, px: { xs: 4, s: 8, md: 10, lg: 25 } }}>
                <Overview overview={landingPageJson?.overview} />
                {/* Competitions Grid */}
                <Box sx={{ mt: 4 }}>
                    <img
                        src={"../img/collage.png"}
                        alt={"kkddk"}
                        style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }}
                    // onError={() => setImgError(true)}
                    />
                </Box>
                <Box sx={{ mt: 4 }}>
                    <CompetitionOverview competitions={competitionsData} />
                </Box>
                <Button style={{ alignContent:"center" }} href="https://www.roundpier.com/competitions">
                    Latest Competitions
                </Button>

                {/* <Box sx={{ mt: 8 }}>
                    <WinnersCards />
                </Box> */}
                {/* Testimonial Section */}
                <Box sx={{ mt: 8, mb: 6 }}>
                    <Testimonials testimonials={landingPageJson?.testimonials} />
                </Box>
            </Box>
            <Footer footer={landingPageJson?.footer} />
        </div>
    );
};

export default LandingPage;
