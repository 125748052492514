import React from 'react';
import { Modal, Box, Button, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const categories = ["Technology", "Finance", "Entreprenurship", "Medicine", "Creative Writing", "Environment"];

export default function ClubForm({ open, onClose, newClub, handleInputChange, handleCreateClub }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <h2>Create a New Club</h2>
        <TextField fullWidth label="Club Name" name="name" value={newClub.name} onChange={handleInputChange} sx={{ mb: 2 }} />
        
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Category</InputLabel>
          <Select name="category" value={newClub.category} onChange={handleInputChange}>
            {categories.map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField fullWidth label="Club Image URL" name="url" value={newClub.url} onChange={handleInputChange} sx={{ mb: 2 }} />
        <TextField fullWidth label="School" name="school" value={newClub.school} onChange={handleInputChange} sx={{ mb: 2 }} />

        {/* Grid for Country, City, Zip */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}><TextField fullWidth label="Country" name="country" value={newClub.country} onChange={handleInputChange} /></Grid>
          <Grid item xs={4}><TextField fullWidth label="City" name="city" value={newClub.city} onChange={handleInputChange} /></Grid>
          <Grid item xs={4}><TextField fullWidth label="Zip Code" name="zipCode" value={newClub.zipCode} onChange={handleInputChange} /></Grid>
        </Grid>

        <TextField fullWidth label="Description" name="description" value={newClub.description} onChange={handleInputChange} multiline rows={4} sx={{ mb: 2 }} />

        <Button variant="contained" onClick={handleCreateClub}>Create</Button>
      </Box>
    </Modal>
  );
}

const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
  width: 800, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2,
};
