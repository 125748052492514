import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import {  Box, Typography, Container } from '@mui/material';
import { StyledButton } from "../commonComponents/StyledComponents";
export const Banner = (props) => {

  const handleLearnMoreClick = () => {
    var element = document.getElementById("competition-overview");
    if(element){
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

  }

  return (
    <header>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '32rem',
          backgroundImage: `url(${props.data?.image})`, // Add the image here
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color
          }}
        />
        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
          <Typography variant="h1" sx={{fontSize:'2rem'}} gutterBottom>
            {props.data ? props.data.title : "Loading"}
          </Typography>
          <Typography variant="h1" paragraph>
            {props.data ? props.data.paragraph: "Loading"}
          </Typography>
          <Box sx={{ mt: 2.5}}>
            {/* <StyledButton
              component={RouterLink}
              to="/signup"
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', mx: 1 }}
            >
              Sign Up
            </StyledButton>
            <StyledButton
              component={RouterLink}
              to="/signin"
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', mx: 1 }}
            >
              Sign In
            </StyledButton> */}
            <StyledButton
             component={RouterLink}
              href="#features"
              variant="text"
              size="large"
              onClick={handleLearnMoreClick}
             >
              Learn More
            </StyledButton>
          </Box>
        </Container>
      </Box>
    </header>
  );
};
