import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "./appStore";
import { getAuth, signOut } from "firebase/auth";
import Confirmation from "../Alerts/Confirmation";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidenav() {
  const theme = useTheme();
  const navigate = useNavigate();
  const updateOpen = useAppStore((state) => state.updateOpen);
  const dopen = useAppStore((state) => state.dopen);
  const auth = getAuth();
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleLogout = () => {
    // Clear specific items from localStorage
    localStorage.removeItem('profile');
    localStorage.removeItem('token');  // Uncomment if needed
    localStorage.removeItem('user');   // Uncomment if needed
  
    // Use navigate or window.location to redirect after clearing storage
    navigate("/");  // If you're using React Router
    // Or you can use: window.location.href = "/";
  };
  

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Confirmation
        openConfirm={openConfirm}
        setOpenConfirm={setOpenConfirm}
        confirmAction={handleLogout}
        message={"Are you sure you want to logout?"}
      />
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#123860",
            pt: "3.5rem",
          },
        }}
        variant="permanent"
        open={dopen}
      >
        <DrawerHeader>
          <Typography
            variant="h5"
            sx={{
              display: { xs: "none", sm: "block" },
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => navigate("/clubs")}
          >
            Roundpier
          </Typography>
          <IconButton
            size="large"
            sx={{ color: "#fff" }}
            onClick={() => updateOpen(!dopen)}
          >
            {dopen ? <ArrowBackIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <List sx={{ height: "100vh", backgroundColor: "#123860", color: "#fff" }}>
          <ListItem disablePadding onClick={() => navigate("/clubs")}>
            <ListItemButton>
              <VideoLibraryIcon sx={{ minWidth: 0, mr: 3 }} />
              <ListItemText primary="Clubs" />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding onClick={() => navigate("/competitions")}>
            <ListItemButton>
              <RoomOutlinedIcon sx={{ minWidth: 0, mr: 3 }} />
              <ListItemText primary="Competitions" />
            </ListItemButton>
          </ListItem> */}
          <Divider sx={{ my: "1rem", borderColor: "#3d5873" }} />
          <ListItem disablePadding onClick={() => navigate("/account")}>
            <ListItemButton>
              <PersonIcon sx={{ minWidth: 0, mr: 3 }} />
              <ListItemText primary="Account" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => setOpenConfirm(true)}>
            <ListItemButton>
              <LogoutIcon sx={{ minWidth: 0, mr: 3 }} />
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
